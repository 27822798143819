/****************************
==============================
   table of content 
==============================

1.    Import files
2.    Header
3.    Banner
4.    About Intro
5     CTA
6.    Course
7.    Registration
8.    Team
9.    Event
10.   Testimonial 
11.   Blog 
12.   Client 
13.   Footer 

******************************/

// import files
// font family
$font-jost: 'Jost', sans-serif;
$font-quicksand: 'Quicksand', sans-serif;
$font-awesome5: "Font Awesome 5 Free";

// colors
$primary-color: #2481B2;
$secondary-color: #2481B2;
$tertiary-color: #59BDE2;
$quinary-color: #95CE74;
$senary-color: #a951ed;
$tertiary-light-color: #00B7FF;

$white-color: #fff;
$black-color: #161851;
$title-color: $black-color;
$body-color: #686f7a;
$border-color: #ECECEC;
$border-color2: #C4C4C4;
$gray-color: #5E687C;
$text-color : $gray-color;

// bg color
$bg-1: #f4f4f4;
$bg-2: #CDE0F2;
$bg-3: #2481B2;
$bg-5:#F1FBFC;

$bg-lighten: $bg-2;

// border radius
$border-radius-sm: 5px;
$border-radius-md: 10px;
$border-radius-lg: 30px;

@mixin transform($property) {
    -webkit-transform: $property;
    -ms-transform: $property;
    transform: $property;
 }
 @mixin border-radius($radius) {
    border-radius: $radius;
    -webkit-border-radius: $radius;
    -ms-border-radius: $radius;
 }
 
 
 //usage: @include shift([property],[duration],[easing]);
 @mixin transition($property: all, $duration: 0.4s, $ease: ease) {
    transition: $property $duration $ease;
    -webkit-transition: $property $duration $ease;
    -moz-transition: $property $duration $ease;
    -ms-transition: $property $duration $ease;
 }
 
 @mixin background-content(
    $repeat: no-repeat,
    $size: cover,
    $position: center center
 ) {
    background-repeat: $repeat;
    background-size: $size;
    background-position: $position;
 }
 
 @mixin background-color($color) {
    background-color: $color;
 }
 @mixin flex-position($display, $contet, $items) {
    display: $display;
    justify-content: $contet;
    align-items: $items;
 }
 
 @mixin absolute($position: absolute, $left: 0, $top: 0) {
    position: $position;
    left: $left;
    top: $top;
 }
 
 @mixin center-position($top: 50%, $left: 50%) {
    top: $top;
    left: $left;
    transform: translate(-#{$top}, -#{$left});
 }
 

//Import Fonts
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700;800;900&family=Quicksand:wght@400;500;600;700&display=swap');

/* ===========================
// Typography
==============================*/

body {
   margin: 0;
   padding: 0;
   font-family: $font-jost;
   font-size: 16px;
   line-height: 28px;
   color: $text-color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
   font-family: $font-quicksand;
   color: $title-color;
   font-weight: 700;
}
h1 {
   font-size: 48px;
   line-height: 60px;
}
h2 {
   font-size: 36px;
   line-height: 48px;
}
h3 {
   font-size: 30px;
   line-height: 36px;
}
h4 {
   font-size: 22px;
   line-height: 36px;
}
h5 {
   font-size: 20px;
   line-height: 30px;
}
h6 {
   font-size: 16px;
   line-height: 26px;
}
/*============================ 
// Global styles
=============================*/
html {
   overflow-x: hidden !important;
   width: 100%;
   height: 100%;
   position: relative;
   text-rendering: optimizeLegibility;
}
ul,
li {
   padding: 0;
   margin: 0;
   list-style: none;
}
a:link,
a:visited,
a:focus {
   text-decoration: none;
   outline: 0;
}
a {
   color: $primary-color;
   @include transition();
   &:hover {
      text-decoration: none;
      color: $primary-color;
   }
}
strong {
   font-weight: 700;
}
img {
   max-width: 100%;
}
input,
textarea,
select,
:focus {
   outline: 0;
   box-shadow: 0;
}

.gap {
    height: 30px;
    display: block;
}

.fw-500 {
   font-weight: 500;
}
.fw-600{
   font-weight: 600;
}
.fw-400 {
   font-weight: 400;
}
.bg-primary-light{ 
   background: $bg-lighten;
}
/*============================
//  margin & padding
============================*/
section, .section{ 
   position: relative;
}
@media (min-width: 1200px) {
   .page-title {
      font-size: 70px;
      margin-bottom: 20px;
   }
}

.section-padding {
   padding: 70px 0;
}

@media (min-width: 768px) {
   .section-padding {
      padding: 100px 0;
   }
   
}
// gutter
@for $i from 1 through 7 {
   .gutter-#{$i}0 {
      margin-top: -($i * 10px)/2 !important;
      margin-bottom: -($i * 10px)/2 !important;
      > li,
      > div {
         padding-top: ($i * 10px)/2 !important;
         padding-bottom: ($i * 10px)/2 !important;
      }
   }
}

div[class*="col-"] {
   position: relative;
}

/*=======================
//  buttons
========================*/
.btn {
   display: inline-block;
   font-weight: 700;
   color: $white-color;
   padding: 11px 40px;
   font-size: 16px;
   line-height: 26px;
   border-radius: 5px;
   border: 2px solid transparent;
   background-color: $secondary-color;
   border-color: $secondary-color;
   @include transition();
   position: relative;
   &:focus {
      outline: 0;
      box-shadow: none;
   }
   &:hover {
      background-color: transparent;
      color: $white-color;
      border-color: $white-color;
   }
   &.btn-primary {
      color: $white-color;
      background-color: $primary-color;
      border-color: $primary-color;
      &:hover {
         background-color: transparent;
         color: $primary-color;
      }
   }
   &.btn-dark {
      color: $white-color;
      background-color: $title-color;
      border-color: $title-color;
      &:hover {
         background-color: transparent;
         color: $white-color;
         border-color: $white-color;
      }
   }
   &.btn-light {
      color: $black-color;
      background-color: $white-color;
      border-color: $white-color;
      &:hover {
         background-color: transparent;
         color: $white-color;
      }
   }
   &.btn-outline-secondary {
      color: $white-color;
      background-color: transparent;
      border-color: $white-color;
      &:hover {
         background-color: $secondary-color;
         border-color: $secondary-color;
      }
   }
   &.btn-outline-dark {
      background-color: transparent;
      color: $title-color;
      border: 1px solid $title-color;
      &.blog-btn {
         font-size: 14px;
         padding: 6px 22px;
      }
      &:hover {
         color: $white-color;
         background-color: $title-color;
         border-color: $title-color;
      }
   }
   &.btn-ins {
      &:hover {
         color: $secondary-color;
         border-color: $secondary-color;
      }
   }
   &.btn-book {
      padding: 4px 0;
      font-size: 13px;
   }
   &.find-btn {
      &:hover {
         background-color: $primary-color;
         border-color: $primary-color;
         color: $white-color;
      }
   }
   &.sm-btn {
      padding: 5px 24px;
      font-size: 14px;
   }
   &.ex-outline {
      &:hover {
         background-color: $primary-color;
         border-color: $primary-color;
      }
   }
   &.btn-secondary-modify {
      &:hover {
         border-color: $secondary-color;
         color: $secondary-color;
      }
   }
}

// btn
.btn.focus,
.btn:focus {
   outline: 0;
   box-shadow: none;
}
// btn primary
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
   box-shadow: none;
}

// btn secondary
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
   box-shadow: none;
}
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
   background-color: transparent;
   border-color: $white-color;
}

// btn outline
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
   box-shadow: none;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle {
   background-color: $secondary-color;
   border-color: $secondary-color;
}
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
   box-shadow: none;
}
.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show > .btn-dark-secondary.dropdown-toggle {
   background-color: $title-color;
   border-color: $title-color;
}

/*============================
//  section-title
============================*/
.section-title {
   margin: 0 0 60px;
   position: relative;
   .sub-title {
      font-size: 15px;
      font-weight: 400;
      color: $black-color;
      letter-spacing: -0.3px;
      display: block;
      text-transform: uppercase;
      line-height: 22px;
      margin: 0 0 10px;
   }
   &.event-blog-title {
      margin: 0 0 25px;
      color: $white-color;
      .sub-title {
         color: $white-color;
      }
   }
   &.about-title2 {
      @media (min-width: 992px) {
         line-height: 60px;
         margin: 0 0 130px;
      }
   }
}

/*============================
//  tp-block-content
============================*/
.tp-block-content {
   .block-title {
      margin: 0 0 28px;
      &.title-2 {
         color: $white-color;
      }
      &.title-3 {
         @media (min-width: 768px) {
            margin: 0;
         }
      }
   }
   p {
      margin: 0 0 18px;
      &:last-of-type {
         margin: 0 0 32px;
      }
   }
   &.tp-banner-content {
      .banner-sub-title {
         font-size: 18px;
         color: $white-color;
         font-family: $font-quicksand;
         font-weight: 400;
         display: inline-block;
         margin: 0 0 25px;
         span {
            color: $primary-color;
            position: relative;
            font-size: 24px;
            font-weight: 700;
            display: inline-block;
            &::after {
               content: "";
               position: absolute;
               left: 0;
               bottom: -12px;
               right: 0;
               margin: 0 auto;
               background: url("../img/gallery/border.png") no-repeat;
               width: 37px;
               height: 9px;
            }
         }
      }
      p {
         color: $white-color;
      }
   }
}

/*============================
// common  list
============================*/
.list {
   .list-item {
      color: $title-color;
      position: relative;
      padding-left: 30px;
      margin: 0 0 12px;
      .about-content2 & {
         color: $white-color;
      }
      .check-icon {
         position: absolute;
         left: 0;
         top: 0;
         bottom: 0;
         margin: auto 0;
         color: $primary-color;
         font-size: 14px;
         line-height: 24px;
         .about-content2 & {
            color: $white-color;
         }
      }
   }
   &.list-2 {
      .list-item {
         .check-icon {
            color: $title-color;
         }
      }
   }
}

/*=========================
//  owl carousel 
===========================*/

// owl nav
.owl-carousel {
   &.owl-theme {
      .owl-nav {
         visibility: hidden;
         opacity: 0;
         @include transition();
         .owl-prev,
         .owl-next {
            color: $white-color;
            font-size: 20px;
            background: $primary-color;
            display: inline-block;
            width: 40px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            border-radius: $border-radius-sm;
            margin: 0;
            padding: 0;
            opacity: 1;
            cursor: pointer;
            @include transition();
            .weekly-top-course &{
               background-color: $secondary-color;
            }
            &:hover {
               background-color: $secondary-color;
            }
            &:focus {
               outline: 0;
               box-shadow: none;
               opacity: 1;
            }
         }
         .owl-next {
            right: 0;
            left: auto;
         }
      }
      .owl-dots {
         .owl-dot {
            &:focus {
               outline: 0;
            }
            span {
               width: 10px;
               height: 10px;
               margin: 30px 6px 0 6px;
               background: #f4f4f4;
               display: block;
               border-radius: 30px;
               opacity: 1;
            }
            &.active {
               span {
                  background: $secondary-color;
               }
            }
         }
      }
   }
   &:hover {
      &.owl-theme {
         .owl-nav {
            visibility: visible;
            opacity: 1;
         }
      }
   }
}

// team owl nav
.team-section {
   .owl-carousel {
      &.owl-theme {
         .owl-nav {
            .owl-prev,
            .owl-next {
               top: 38%;
            }
         }
      }
   }
}
// category-slider owl nav
.category-section {
   .owl-carousel {
      &.owl-theme {
         .owl-nav {
            .owl-prev,
            .owl-next {
               border-radius: 50%;
            }
         }
      }
   }
}
// category-slider owl nav
.course-preview-slider2 {
   &.owl-carousel {
      &.owl-theme {
         .owl-nav {
            .owl-prev,
            .owl-next {
               top: 42%;
            }
         }
      }
   }
}

// owl nav

.course-preview-area {
   .owl-carousel {
      &.owl-theme {
         .owl-nav {
            visibility: visible;
            opacity: 1;
            .owl-prev,
            .owl-next {
               font-size: 16px;
               width: 35px;
               height: 35px;
               line-height: 35px;
               right: 45px;
               left: auto;
               top: -50px;
            }
            .owl-next {
               left: auto;
               right: 0;
            }
         }
      }
   }
}

.bg-2 {
   background-color: #242937;
 }
 
/*=======================
// back-to-top
========================*/

#back-to-top {
   font-size: 20px;
   color: $white-color;
   background: $primary-color;
   width: 40px;
   height: 40px;
   line-height: 40px;
   text-align: center;
   border-radius: $border-radius-sm;
   position: fixed;
   right: 30px;
   bottom: 30px;
   z-index: 99;
   display: none;
   cursor: pointer;
   &:hover {
      background-color: $secondary-color;
      color: $white-color;
   }
}

/*=======================
// meta tag
========================*/
.meta {
   color: $white-color;
   background-color: $primary-color;
   display: inline-block;
   padding: 3px 15px;
   border-radius: $border-radius-sm;
   &:hover {
      color: $white-color;
   }
   i {
      margin-right: 4px;
   }
}


/*=========================
// prelaoder
=========================*/

#loading {
   background-color: $primary-color;
   height: 100%;
   width: 100%;
   position: fixed;
   z-index: 9999;
   margin-top: 0px;
   top: 0px;
}

#loading-center {
   width: 100%;
   height: 100%;
   position: relative;
}

#loading-center-absolute {
   position: absolute;
   left: 50%;
   top: 50%;
   height: 200px;
   width: 200px;
   margin-top: -100px;
   margin-left: -100px;
}

#object {
   width: 80px;
   height: 80px;
   background-color: #fff;
   -webkit-animation: animate 1s infinite ease-in-out;
   animation: animate 1s infinite ease-in-out;
   margin-right: auto;
   margin-left: auto;
   margin-top: 60px;
   @media (max-width: 991px) {
      width: 60px;
      height: 60px;
   }
}

@-webkit-keyframes animate {
   0% {
      -webkit-transform: perspective(160px);
   }

   50% {
      -webkit-transform: perspective(160px) rotateY(-180deg);
   }

   100% {
      -webkit-transform: perspective(160px) rotateY(-180deg) rotateX(-180deg);
   }
}

@keyframes animate {
   0% {
      transform: perspective(160px) rotateX(0deg) rotateY(0deg);
      -webkit-transform: perspective(160px) rotateX(0deg) rotateY(0deg);
   }

   50% {
      transform: perspective(160px) rotateX(-180deg) rotateY(0deg);
      -webkit-transform: perspective(160px) rotateX(-180deg) rotateY(0deg);
   }

   100% {
      transform: perspective(160px) rotateX(-180deg) rotateY(-180deg);
      -webkit-transform: perspective(160px) rotateX(-180deg) rotateY(-180deg);
   }
}
.container {
   position: relative;
}
/* overlay */
.overlay {
   position: relative;
   &::before {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba($title-color, 0.5);
   }
}

/* ===============
/ Error Page 
==================*/

.error-page{
   h2{
      font-size: 100px;
      font-weight: 800;
      line-height: 0.8;
      margin-bottom: 20px;
   }
   h3{
      margin-bottom: 20px;
   }
}
@media (min-width: 992px){
   .error-page{
      h2{
         font-size: 200px;
      }
   }
}

.highlight{
   background: $bg-3;
   display: inline-block;
   line-height: .8;
}

.block-content {
   .block-desc {
      font-size: 18px;
      line-height: 1.3;
   }
   .block-heading + .block-desc { 
      margin-top: 20px;
   }
}


.block-heading {
   font-size: 28px;
   color: $title-color;
   font-weight: 700;
   
   .sub-heading{
      font-size: 14px;
      display: inline-block;
      line-height: 1.3;
      color: $primary-color;
      position: relative;
      padding-right: 25px;
      margin-bottom: 10px;
      &::after{
         position: absolute;
         right: 0;
         top: -7px;
         width: 25px;
         height: 25px;
         content: '';
         background: url(../images/title-shape.png) no-repeat center center / cover;
      }
   }
   strong{
      display: block;
      line-height: 40px;
   }
}

@media (min-width: 767px){
   .block-content {
      .block-desc {
         font-size: 18px;
         line-height: 26px;
      }
   }
   .block-heading {
      font-size: 38px;
      strong{
         line-height: 54px;
      }
   }
}
@media (min-width: 991px){
   .block-heading {
      font-size: 48px;
      strong{
         line-height: 74px;
      }
   }
}

.list {
   li {
      display: flex;
      align-items: center;
      font-size: 18px;
      line-height: 30px;
      font-weight: 700;
      margin-bottom: 20px;
      .icon {
         margin-right: 15px;
         img {
            max-width: 30px;
         }
      }
   }
   &.list-icon {
      li {
         align-items: flex-start;
         font-weight: 400;
         .icon {
            color: $primary-color;
            i {
               font-size: 18px;
            }
         }
      }
   }
   &.list-icon-2 {
      li {
         font-size: 18px;
         .content {
            strong {
               display: block;
               margin-bottom: 10px;
            }
            p {
               color: #5E687C;               
            }
         }
      }
   }
}

@media (min-width: 991px) {
   .list {
      li {
         font-size: 24px;
         .icon {
            img {
               max-width: 46px;
            }
         }
      }
   }
}
// block image 

.block-image{
   img{
      max-width: 100%;
   }
}
// bg image 
.imagebg{
   background-size: contain;
   position: absolute;
   width: 50%;
   left: 0;
   top: 0;
   height: 100%;
   background-repeat: no-repeat;
   background-position: center;
   &.bg-left{
      left: 0;
      right: auto;
   }
   &.bg-right{
      left: auto;
      right: 0;
   }
}

// sidebar css 
h4.widget-title {
   margin-bottom: 20px;
}
.post-list {
   .post-item {
      display: flex;
      .post-thumbs {
         width: 130px;
         margin-right: 25px;
         img {
            max-width: 100%;
         }
      }
      .post-content {
         .post-title {
            font-size: 14px;
            line-height: 20px;
            margin-top: -5px;
            a {
               color: $title-color;
            }
            &:hover {
               a {
                  color: $primary-color;
               }
            }
         }
         .post-meta {
            li {
               line-height: 1;
               color: #AFB2B8;
            }
         }
      }
   }
}

.cat-item {
   display: flex;
   justify-content: space-between;
   font-size: 14px;
   font-weight: 700;
   border-bottom: 1px solid #DCDCDC;
   padding: 10px 0;
   font-family: $font-quicksand;
   a {
      color: $title-color;
      font-weight: 700;
      span {
         color: #AFB2B8;  
      }
   }
   .icon {
      color: $primary-color;
      margin-right: 5px;
   }
}
.tag-list {
   li {
      display: inline-block;
      margin-bottom: 5px;
      a {
         background: #F6F6F6;
         padding: 10px 20px;
         display: block;
         line-height: 1;
         color: $title-color;
      }
   }
}
.search-form { 
   position: relative;
   margin-bottom: 40px;
   .form-group {
      position: relative;
   }
   .search-btn {
      position: absolute;
      right: 0;
      top: 0;
      background: transparent;
      border: navajowhite;
      bottom: 0;
      padding: 0 15px;
   }
}

// quote 
blockquote {
   background: #F6F6F6;
   padding: 25px 40px;
   border-left: 1px solid $primary-color;
   .icon {
      max-width: 46px;
      margin-bottom: 20px;
   }
   span {
      display: block;
      color: #161851;
      font-size: 20px;
      line-height: 28px;
   }
}

// social 

.social {
   li {
      display: inline-block;
      a {
         width: 42px;
         height: 42px;
         background: $title-color;
         color: $white-color;
         font-size: 15px;
         line-height: 30px;
         text-align: center;
         padding: 7px;
         border-radius: 50%;
         display: inline-block;
         &:hover {
            background: $primary-color;
         }
      }
   }
}

// comment 
.comments-list {
   .comment-item {
      display: flex;
      margin-bottom: 20px;
      .user-thums {
         max-width: 100px;
         margin-right: 20px;
         img {
            width: 100%;
            border-radius: 50%;
         }
      }
      .content {
         .user-name {
            font-size: 14px;
            line-height: 20px;
         }
         .comment-desc {
            font-size: 15px;
            line-height: 20px;
            margin: 0 0 10px;
         }
         .rep {
            font-size: 12px;
            color: #bdbdbd;
            line-height: 20px;
         }
      }
   }
}

// Header
.header {
    &-main {
        padding: 12px 0px;
        transition: all .4s;
        width: 100%;
        position: relative;
        z-index: 9;
        .is-transparent &, .has-fixed & {
            width: 100%;
            z-index: 49;
        }
        .is-transparent & {
            position: absolute !important;
        }
        .header-s1 &{
            background: $white-color;
        }
        .header-s1.has-fixed &{
            .bg-dark-alt &{
                background: lighten($black-color, 5%);
            }
        }
        .has-fixed &{
            position: fixed !important;
            box-shadow: 0 0 20px 0 rgba($black-color, 0.08);
            background: $white-color;
            top: 0;
        }
        .has-fixed.is-shrink &{
            padding: 8px 0;
        }
    }
    &-container{
        &:not(.container){
            width: 100%;
            padding: 0 15px;
        }
    }
    &-wrap{
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }
    &-logo{
        margin: 6px 0;
        flex-shrink: 0;
        img{
            max-width: 200px;
        }
    }
    &-navbar {
        position: fixed;
        left: -280px;
        top: 0;
        width: 260px;
        height: 100vh;
        transition: all 0.4s;
        z-index: 2;
        &::before, &-overlay{
            position: absolute;
            height: 100%;
            width: 100%;
            content: '';
            top: 0;
        }
        &::before{
            background: #fff;
            left: 50%;
            transform: translateX(-50%);
            transition: all .4s;
            .is-dark & {
                background: lighten($primary-color,5%);
            }
        }
        &-overlay{
            background: rgba($black-color, 0.7);
            left: 100%;
            width: 0;
            z-index: -1;
            opacity: 0;
            visibility: hidden;
            transition: opacity .3s;
            transition-delay: .2s
        }
        &.menu-shown{
            left: 0 !important;
            .header-navbar-overlay {
				width: 100vw;
                opacity: 1;
                visibility: visible;
            }
        }
    }
    &-menu{
        justify-content: flex-end;
        padding: 30px 25px;
        .header-navbar-classic & {
            padding: 0;
        }
    }
    &-btn{
        .sign-btn {
            padding: 0 15px 0 0;
            i{
                margin-right: 5px;
                color: $primary-color;
            }
        }
    }
}
.banner{
    min-height: 72px;
    .is-boxed &{
        padding-left: 15px;
        padding-right: 15px;
    } 
}
@media (min-width:375px){
    .header {
        &-navbar:not(.header-navbar-classic) {
            left: -310px;
            width: 290px;
        }
    }
}
@media (min-width:576px){
    .header {
        &-navbar:not(.header-navbar-classic) {
            left: -360px;
            width: 340px;
        }
        &-main{
            .is-boxed &{
                padding: 12px 40px;
            }
            .is-boxed.has-fixed.is-shrink &{
                padding: 8px 40px;
            }
        }
    }
    .banner{
        .is-boxed &{
            padding-left: 40px;
            padding-right: 40px;
        } 
    }
   .blog-single {
      .banner {
         .col-md-7 {
            width: 100%;
         }
      }
   }
}

// Logo Alternet
.logo-dark, .is-transparent:not(.has-fixed) .logo-light, .is-dark .logo-light,.bg-dark-alt .logo-light{
    display: block;
}
.logo-light, .is-transparent:not(.has-fixed) .logo-dark, .is-dark .logo-dark ,.bg-dark-alt .logo-dark{
    display: none;
}
.header-s2 {
    .logo-light{display: block;}
    .logo-dark{display: none;}
}


@media (min-width:992px){
    .header {
        &-navbar:not(.header-navbar-classic){
            height: auto;
            width: auto;
            left: 0;
        }
        &-navbar{
            &-s2{
                flex-grow: 1;
                .menu{
                    margin-left: auto;
                    margin-right: auto;
                    &-btns{
                        padding: 0;
                        margin-left: 0;
                        &::before{
                            display: none;
                        }
                    }
                }
            }
        }
    	&-main {
            padding: 30px 0;
            .is-boxed &{
                padding: 12px 40px;
            }
            .is-boxed.has-fixed.is-shrink &{
                padding: 8px 40px;
            }
            .header-btn{
                margin: 0;
            }
		}
        &-nav-toggle{
            display: none;
        }
        &-container{
            &:not(.container){
                padding: 0 25px;
            }
        }
        &-banner{
            min-height: 98px;
        }
        &-box{
            .is-boxed &{
                .banner{
                    padding: 0 40px;
                }
            }
        }

    }
}

.header {
    &-menu {
        max-height: 100vh;
        overflow: hidden;
        overflow-y: auto;
    }
    &-nav-toggle{
        height: 44px;
    }
}

@media (min-width:1280px){
    .header{
        &-main {
            .is-boxed &{
               padding: 20px 40px;
            }
            .header-s1.has-fixed.is-shrink &{
                padding: 12px 40px;
                top: 0;
            }
           
        }
        &-box{
            .is-boxed &{
                .banner{
                    padding: 0 40px;
                }
            }
        }
    }
}
@media (min-width:1600px){
    .header{
        &-main {
            .is-boxed &{
               padding: 30px 140px;
            }
            .is-boxed.has-fixed.is-shrink &{
               padding: 20px 140px;
            }
           
        }
        &-box{
            .is-boxed &{
                .banner{
                    padding: 0 140px;
                }
            }
        }
    }
}

// Menu/Nav
.menu {
    &-item {
        position: relative;
        border-bottom: 1px solid rgba($white-color, 0.75);
        .is-dark &{
            border-color: rgba($white-color, 0.1);
        }
        &:last-child {
            border-bottom: 0;
        }
        > ul, .menu-sub {
            display: none;
        }
        a {
            padding: 12px 18px;
            display: block;
            position: relative;
            font-weight: 700;
            color: $black-color;
            .badge{
                margin-left: 5px;
                border-radius: 8px;
                padding: 3px 8px;
                font-size: 9px;
            }
            .menu-drop &{
                text-transform: capitalize;
            }
            &.active,&:hover{
                color: $primary-color;
            }
        }
    }
    &-sub{
        .menu-item{
            &:first-child{
                border-top: 1px solid rgba($white-color, 0.75);
            }
        }
        .is-dark &{
            .menu-item{
            &:first-child{
                    border-color: rgba($white-color, 0.1);
                }
            }
        }
        .menu-sub{
            margin-top: -5px;
            margin-bottom: 10px;
            .menu-item{
                border-bottom: none;
                &:first-child{
                    border-top: none;
                }
                a {
                    padding-top: 6px;
                    padding-bottom: 6px;
                   
                }
            }
        }
        
    }
    &-mega{
        a {
            .bg-dark-alt &{
                color: $primary-color;
            }
        }
    }
    &-item{
        a{
            .is-dark & {
                color: #fff;
            }
        }
        > a.active{
            color: $primary-color;
        }
    }
}

// Has Sub
.has-sub{
    > a{
        position: relative;
        &:after{
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            content: '\f078';
            font-family: 'Font Awesome 5 Free';
            font-size: 8px;
            font-weight: 700;
            transition: transform .4s;
        }
    }
    &.open-nav{
        > a:after{
            transform:translateY(-50%) rotate(-180deg);
        }
    }
}
.navbar{
    &-toggle{
        width: 44px;
        height: 44px;
        text-align: center;
        display: inline-block;
        border-radius: 50%;
        padding: 5px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s;
        &.active{
            color: $primary-color;
            position: relative; 
            z-index: 49;
        }
        &:hover, &.active {
            background: rgba($white-color, 0.2);    
            .bg-dark-alt &,.bg-dark &{
                background: transparent;
            }
        }
        .is-transparent:not(.has-fixed) &{
            color: #fff;
            &:hover, &.active {
                background: rgba($white-color, 0.2);    
            }
        }
    }
}

//Nav Toggle
.toggle {
    &-line {
        position: relative;
        width: 32px;
        height: 32px;
        display: block;
        .is-transparent:not(.has-fixed) &,.is-dark.has-fixed &{
            color: #fff;
        }
        &::after, &::before, &>span {
            content: "";
            position: absolute;
            background: currentColor;
            transition: all 0.2s;
            width: 24px;
            height: 2px;
            left: 4px;
            border-radius: 3px
        }
        &::before {
            top: 8px
        }
        &::after {
            bottom: 8px
        }
        >span {
            top: 50%;
            margin-top: -1px;
            width: 20px;
        }
        .active & {
            color: #fff;
            .bg-dark-alt &{
                color: $white-color;
            }
            &::before, &::after {
                width: 28px;
                left: 2px
            }
            &::before {
                transform: rotate(45deg);
                top: 15px
            }
            &::after {
                transform: rotate(-45deg);
                bottom: 15px
            }
            >span {
                opacity: 0;
                visibility: hidden
            }
        }
    }
}
@media (min-width:576px){
    .menu{
        &-btns{
            &::before{
                display: block;
            }
            > li {
                a {
                    padding: 12px 22px;
                }
            }
        }
    }
}
@media (min-width:992px){
    .header {
        &-menu {
            width: auto;
            align-items: center;
            position: static;
            background: transparent;
            padding: 0;
            margin: 0;
            border-radius: 0;
            overflow: visible;
        }
        &-navbar{
            margin-top: 0;
            position: relative;
            &:before{
                display: none;
            }
            .has-fixed &{
                margin-top: 0;
            }
        }
    }
    .menu {
        display: flex;
        align-items: center;
        padding: 0;
        justify-content: center;
        &-item {
            border-bottom: none;
            &:hover{
                z-index: 9999;
            }
            &:last-child{
                a{
                  display: block;  
                }
            }
        }
        &-sub {
            position: absolute;
            max-width: 220px;
            min-width: 220px;
            background: $white-color;
            padding: 15px 0;
            top: 100%;
            left: 20px;
            display: block !important;
            z-index: 99;
            opacity: 0;
            visibility: hidden;
            transition: all .4s;
            transform: translateX(0) translateY(15px);
            border-radius: 5px;
            box-shadow: 0 0 12px rgba(0,0,0, .16);
            a{
                width: 100%;
                display: block;
            }
         
            .menu-item:hover > & {
                opacity: 1;
                visibility: visible;
                transform: translateX(0) translateY(5px);
            }
            .menu-sub{
                left: calc(100% - 15px);
                top: -15px;
                margin: 0;
                .menu-item{
                    a {
                        padding-top: 12px;
                        padding-bottom: 12px;
                    }
                }
            }
        }
        &-mega{
            position: absolute;
            left: 50%;
            background: #fff;
            padding: 15px 10px;
            top: 100%;
            display: block !important;
            z-index: 99;
            opacity: 0;
            visibility: hidden;
            transition: all .4s;
            transform: translateX(-50%) translateY(-8px);
            .menu-item:hover > & {
                opacity: 1;
                visibility: visible;
                transform: translateX(-50%) translateY(0);
            }
            .menu-item{
                a {
                    padding-top: 8px;
                    padding-bottom: 8px;
                }
            }
            &-innr{
                display: flex;
                > div,ul{
                    flex-grow: 1;
                }
                .menu-mega-2clmn & {
                    > div,ul{
                        width: 50%;
                    }
                }
                .menu-mega-3clmn & {
                    > div,ul{
                        width: 33.33%;
                    }
                }
            }
            &-2clmn{
                min-width: 350px;
                max-width: 350px;
            }
            &-3clmn{
                min-width: 460px;
                max-width: 460px;
            }
        }
        &-btns{
            display: flex;
            margin-bottom: -10px;
            margin-left: 12px;
            padding-left: 15px;
            padding-top: 0;
            > li{
                padding: 10px;
                &:last-child{
                    padding-right: 0;
                }
            }
        }
        .has-megamenu{
            &.menu-item{
                position: static;
            }
            .menu-mega-4clmn{
                max-width: 960px;
                min-width: 960px;
                margin: 0 auto;
                transform: translateX(-61.7%) translateY(0px);
                border-right: 1px solid #fff;
                .menu-mega-list{
                    text-align: center;
                    &:not(:last-child){
                        border-right: 1px solid #f1f2eb;
                        &.style-img{
                            border-color: transparent;
                        }
                    }
                    li.menu-item{
                        text-transform: uppercase;
                        font-weight: 500;
                    }
                }
            }
            .menu-image{
                max-width: 100%;
            }
        } 
        
    }
    .has-sub{
        > a{
            position: relative;
        }
        &.menu-item:last-child{
            .menu-sub:not(.menu-mega){
                .menu-item{
                    > a{
                        &:after{
                            transform:translateY(-50%) rotate(90deg);
                            left: 15px;
                            right: auto;
                        }
                    }
                }
            }
            .menu-mega{
                left: auto;
                right: 0;
                transform: translateX(0) translateY(-8px);
            }
            &:hover{
                .menu-mega{
                    transform: translateX(0) translateY(0);
                }
            }
        }
        .menu-sub & {
            > a{
                &:after{
                    transform:translateY(-50%) rotate(-90deg);
                    right: 15px;
                }
            }
        }
    }
    .has-fixed.is-shrink .header-main{
        .menu .has-megamenu .menu-mega-4clmn {
            transform: translateX(-61.7%) translateY(-4px);
        }
    }
}
@media (min-width:1200px){
    .menu {
        &-item {
            > a {
                padding: 8px 25px;
            }
            &.has-sub{
                > a{
                    padding-right: 25px !important;
                    &:after{
                        right: 8px;
                    }
                }
                .menu-drop{
                    .has-sub{
                        a::after{
                            right: 18px;
                        }
                    }
                }
            }
        }
        &-sub {
            a{
                padding: 10px  20px;
            }
        }
        .has-megamenu{
            .menu-mega-4clmn{
                max-width: 1140px;
                min-width: 1140px;
                transform: translateX(-65.7%) translateY(-4px);
            }
        } 
    }
    .has-fixed.is-shrink .header-main .menu .has-megamenu .menu-mega-4clmn {
        transform: translateX(-65.7%) translateY(-4px);
    }
}

@media (min-width:992px){
    .is-transparent:not(.has-fixed) {
        .menu > .menu-item{
            > a {
                color: $white-color
            }
            &:hover{
                > a{
                    color: $primary-color;
                }
            }
        } 
        .menu{
            &-btns{
                .btn{
                    color: $white-color
                }
            }
        }
    }
}
@media (min-width:1600px){
    .menu{
        &-btns{
            margin-left: 25px;
            padding-left: 22px;
            > li{
                .search{
                    margin-right: 10px;
                }
            }
        }
    }
    .menu-container{
        max-width: 1427px;
    }
    .header-main{
        padding: 30px 0;
    }
}

// hero 
.hero-section {
	background-color: $bg-2;
	width: calc(100% - 15px);
	margin: 0 auto;
	position: relative;
	padding: 100px 0;
}
.mp-left-shpe {
	position: absolute;
	top: 30%;
	img {
		max-width: 30px;
	}
}

.banner {
	&-title {
		color: $black-color;
		padding-right: 30px;
		font-size: 40px;
		line-height: 1.3;	
	}
	&-content {
		.lead {
			font-size: 18px;
			line-height: 28px;
			color: $black-color;
		}
		.notify-btn {
			color: #00b7ff;
		}
	}
}

@media (min-width: 992px) {
	.hero-section {
		padding: 0;
	}
}

@media (min-width: 992px) {
	.hero-section {
		width: calc(100% - 30px);
	}
	.hero-image-wrapper {
		margin-left: -50px;
		.hero-image {
			max-width: 850px;
		}
	}
	.hero-wrapper {
		background-color: $bg-2;
		padding: 30px 35px;
	}
	.mp-left-shpe {
		img {
			max-width: 100px;
		}
	}
	.banner {
		&-content {
			.lead {
				font-size: 24px;
				line-height: 38px;
			}
		}
	}
}

@media (min-width: 1600px) {
	.hero-section {
		width: calc(100% - 110px);
	}
	.banner {
		&-title {
			font-size: 82px;
			line-height: 1.3;
		}
	}
}

// counter 

// counter
.counterup-area {
	padding: 80px 0;
}
.test-img {
	img {
		height: 40px;
		width: 40px;
		border-radius: 50%;
		margin-right: 15px;
	}
}
.count {
	font-size: 50px;
	line-height: 65px;
	margin: 0 0 5px;
}
.count-title {
	font-size: 18px;
	line-height: 30px;
	color: $gray-color;
}
.counter-widget{
	&.style2 {
		.count {
			font-size: 72px;
			line-height: 74px;
			font-weight: 700;
			color: #161851;
		}
	}
}
@media (min-width: 767px) {
	.vertical-devider {
		border-right: 1px solid $border-color2;
	}

	.test-title {
		font-size: 30px;
		line-height: 36px;
	}
}
@media (min-width: 991px) {
	.counterup-area {
		padding: 120px 0;
	}
	.count-title {
		font-size: 24px;
		line-height: 36px;
		color: $gray-color;
	}
	.counter-left {
		padding-left: 30px;
	}
	.count {
		font-size: 90px;
		line-height: 105px;
		margin: 0 0 5px;
	}
}

// cta 


/*==========================
//  cta section 
===========================*/

.cta-block{
    background-color: $primary-color;
    padding: 120px 0;
    position: relative;
    overflow: hidden;
    &::after {
        position: absolute;
        content: "";
        right: -150px;
        top: -180px;
        background: url(../images/circle-shape.png) no-repeat center center/contain;
        width: 400px;
        height: 400px;
        border-radius: 50%;
    }
    .block-title {
       font-size: 30px;
       color: $white-color;
       line-height: 1.3;
       margin-bottom: 20px;
    }
    .desc {
      padding: 0 20px;
      p {
        font-size: 18px;
        font-weight: 500;
        color: $white-color;
        margin-bottom: 20px;
      }
    }
    .btn {
      font-size: 18px;
    }
}
 
@media (min-width: 991px){
    .cta{
       padding: 0;
    }
    .cta-block {
       background-color: $primary-color;
       margin-bottom: -190px;
       &::before {
            position: absolute;
            content: '';
            left: 0;
            top: -40px;
            background: url(../images/dots-shape.png) no-repeat center center / contain;
            width: 180px;
            height: 180px;
        }
        .block-title {
          font-size: 48px;
          margin-bottom: 23px;
        }
       .desc {
            p {
                font-size: 26px;
                margin-bottom: 35px;
            }
       }
    }
}
 
.play-btn {
    display: inline-block;
    font-size: 20px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    line-height: 100px;
    text-align: center;
    background-color: $secondary-color;
    color: $white-color;
    &:hover {
       color: $white-color;
       background-color: $primary-color;
    }
}

// master 



/*==========================
// header 
===========================*/

.header {
   @include absolute();
   right: 0;
   margin: 0 auto;
   z-index: 99;
}

/*==========================
// Banner  
===========================*/

.banner {
   @include background-content();
   position: relative;
   display: flex;
   align-items: center;
   z-index: 4;
   padding: 100px 0;
   &.banner-slide {
      height: 100vh;
      padding: unset;
   }
   &-box {
      background: #FDF7E9;
      border-radius: 10px;
      min-height: 530px;
      margin: 0 auto;
      background-size: 100%;
      background-repeat: no-repeat;
      @media (min-width: 992px) {
         width: calc(100% - 120px);
      }
   }
}

//  slider content animation
#banner__slider {
   .owl-item {
      &.active {
         .tp-banner-content {
            .banner-sub-title {
               animation-duration: 1s;
               -webkit-animation-duration: 1s;
               animation-fill-mode: both;
               -webkit-animation-fill-mode: both;
               animation-name: fadeInDown;
               animation-delay: 0.3s;
            }
            .block-title {
               animation-duration: 1s;
               -webkit-animation-duration: 1s;
               animation-fill-mode: both;
               -webkit-animation-fill-mode: both;
               animation-name: fadeInDown;
               animation-delay: 0.3s;
            }
            p {
               animation-duration: 1s;
               -webkit-animation-duration: 1s;
               animation-fill-mode: both;
               -webkit-animation-fill-mode: both;
               animation-name: fadeInUp;
               animation-delay: 0.3s;
            }
            .block-btn {
               animation-duration: 1s;
               -webkit-animation-duration: 1s;
               animation-fill-mode: both;
               -webkit-animation-fill-mode: both;
               animation-name: fadeInUp;
               animation-delay: 0.3s;
            }
         }
      }
   }
}

// banner breadcrumb
.banner-breadcrumb {
   justify-content: center;
   .breadcrumb-item {
      font-weight: 400;
      a {
         color: $title-color;
         &:hover {
            color: $primary-color;
         }
      }
      &.active {
         color: $primary-color;
         font-weight: 700;
      }
      &:last-of-type {
         &::before {
            color: $title-color;
         }
      }
   }
}

@media (max-width: 991px) {
   .banner {
      padding: 180px 0 100px;
     
   }
}

@media (max-width: 767px) {
   .banner {
      padding: 150px 0 80px;
     
   }
}

// tab nav
.tab-menu {
   position: relative;
   background-color: #f2f2f2;

   .menu-item {
      margin: 0;
      border-right: 2px solid $white-color;
   }

   .menu-link {
      font-size: 14px;
      line-height: 20px;
      color: $title-color;
      font-weight: 700;
      display: inline-block;
      padding: 12px 27px;
      position: relative;
      .tab-menu-icon {
         margin-right: 4px;
      }
      &.active,
      &:hover {
         background-color: $white-color;
         .tab-menu-icon {
            color: $primary-color;
         }
         &:before {
            visibility: visible;
            opacity: 1;
         }
      }

      &:before {
         content: "";
         position: absolute;
         left: 0;
         top: 0;
         width: 100%;
         height: 4px;
         background-color: $primary-color;
         visibility: hidden;
         opacity: 0;
         @include transition();
      }
   }
}

// tab-content
.tab-item {
   padding: 50px 0;
}

.social-links {
   li {
      display: inline-block;
      margin-right: 5px;
      a {
         font-size: 18px;
         &.fb {
            color: #1877f2;
         }
         &.tw {
            color: #55acf2;
         }
         &.in {
            color: #007bb5;
         }
         &.be {
            color: #ff4500;
         }
      }
      &:last-of-type {
         margin-right: 0;
      }
   }
}

/*==========================
// start testimonial
===========================*/
.testimonial {
   &-item{
      .thumb{
         padding-left: 30px;
         position: relative;
         &::before{
            position: absolute;
            content: url(../images/dots-shape2.png);
            left: 0;
            top: 30px;
            bottom: 0;
            width: 100px;
            height: 100%;
            z-index: -1;
         }
         img{
            max-width: 350px;
         }
      }
   }
}

// test-content-box
.quote-content-box {
   background-color: $white-color;
   padding: 30px;
   .quote-icon {
      margin-bottom: 25px;
      width: 46px;
   }
   .quote-desc {
      font-size: 18px;
      line-height: 30px;
      font-style: italic;
      margin: 0 0 20px;
   }
   .author-info {
      line-height: 22px;
      img {
         max-width: 178px;
      }
      .name {
         font-size: 14px;
         line-height: 22px;
         color: $title-color;
         font-weight: 400;
      }
      &.style2 {
         display: flex;
         align-items: center;
         .author-image {
            width: 60px;
            height: 60px;
         }
         .author-content {
            margin-left: 15px;
         }
         .title {
            font-weight: 700;
            color: #161851;
         }
         .designation {
            margin: 0;
         }
      }
   }
}

@media (min-width: 991px){
   .testimonial {
      &-item{
         .thumb{
            padding-left: 120px;
         }
      }
   }
   .testimonial-2 {
      .quote-desc {
         font-size: 24px;
      }
   }
   .quote-content-box{
      padding-right: 100px;
   }
}

/*==========================
// start blog
===========================*/

.blog {
   &:hover {
      .blog-img {
         img {
            transform: scale(1.08);
         }
      }
      .blog-content {
         .blog-title {
            a {
               color: $primary-color;
            }
         }
      }
   }
}

// blog img
.blog-img {
   overflow: hidden;
   border-radius: $border-radius-sm;
   img {
      width: 100%;
      height: auto;
      @include transition();
      transform: scale(1);
      object-fit: cover;
   }
}

// blog content
.blog-content {
   padding: 20px 0 0;
   .blog-meta {
      display: flex;
      align-items: center;
      margin: 0 0 10px;
      .meta-list {
         li {
            display: inline-block;
            color: #AFB2B8;
            text-transform: capitalize;
            margin-right: 9px;
            font-size: 15px;
            a {
               display: inline-block;
               margin-left: 5px;
               color: inherit;
               font-family: $font-jost;
               &:hover {
                  color: $primary-color;
               }
            }

            &.date {
               position: relative;
               padding-left: 12px;
               &::before {
                  content: "";
                  position: absolute;
                  left: 0;
                  top: 0;
                  bottom: 0;
                  margin: auto 0;
                  width: 3px;
                  height: 3px;
                  background-color: #aab7cf;
                  border-radius: 50%;
               }
            }
         }
      }
   }
}

.blog-title {
   margin: 0 0 20px;
   font-size: 22px;
   line-height: 30px;
   a {
      color: $title-color;
   }
   &.title-md {
      font-size: 28px;
      line-height: 38px;
   }
   &.title-lg {
      font-size: 38px;
      line-height: 48px;
   }
}

.post-footer {
   padding: 30px 0;
   border-bottom: 1px solid #ECECEC;
}

@media (min-width: 992px) {
   .post-footer {
      padding: 60px 0;
   }
}

// right side blog-post-wrapper
.blog-post-wrapper {
   border: 1px dashed #ececec;
   border-radius: $border-radius-sm;
   padding: 0 30px;
}

// post-widget
.post-widget {
   padding: 35px 0;
   border-bottom: 1px dotted #ececec;
   .post-widget-title {
      font-size: 24px;
      line-height: 30px;
      margin: 0 0 25px;
   }
   &:last-of-type {
      border-bottom: none;
   }
   
}

.related-post {
   padding: 60px 0;
   .sec-title {
      margin-bottom: 30px;
      font-size: 28px;
   }
}

/*==========================
// Client Logo
===========================*/
.client-section {
   .single-client {
      border: 1px solid $border-color;
      padding: 30px;
      img {
         max-width: 127px;
         margin: 0 auto;
      }
   }
}

/*==========================
// start footer
===========================*/

// footer-top
.footer-top {
   padding: 50px 0;
}
// footer widget
.footer-widget {
   .footer-logo {
      margin: 0 0 25px;
      img{
         max-width: 103px;
      }
   }
   .widget-text {
      color: #c4c4c4;
      margin: 0 0 30px;
   }
   .footer-social {
      li {
         display: inline-block;
         margin-right: 18px;
         font-family: $font-jost;
         font-weight: 600;
         &.list-text {
            color: #c4c4c4;
            margin-right: 20px;
         }
         a {
            display: inline-block;
            color: $white-color;
            &:hover {
               color: $primary-color;
            }
         }
      }
   }
   .widget-title {
      font-size: 18px;
      line-height: 40px;
      color: $white-color;
      font-weight: 700;
      margin: 0 0 20px;
      position: relative;
   }
   .widget-list {
      li {
         margin-bottom: 10px;
         color: #acacae;
         a {
            display: inline-block;
            color: #acacae;
            transform: translateX(0);
            &:hover {
               color: $primary-color;
               transform: translateX(3px);
            }
         }
      }
      &.widget-list2 {
         li {
            position: relative;
            margin: 0 0 15px;
            line-height: 30px;
            padding-left: 30px;
            a {
               &:hover {
                  transform: translateX(0);
               }
            }
            .con-icon {
               @include absolute();
               top: 7px;
               bottom: 0;
               margin: auto 0;
               color: $secondary-color;
            }
         }
      }
   }
}

// footer bottom
.footer-bottom {
   padding: 30px 0;
}
.copy-text {
   font-size: 14px;
   color: #acacae;
   margin: 0;
   .heart-icon {
      margin: 0 5px;
      display: inline-block;
      color: $primary-color;
   }
}

@media (min-width: 992px) {
   .footer-top-1 {
      padding: 250px 0 60px;
   }
}

@media (max-width: 991px) {
   .footer-top {
      padding: 80px 0;
   }
  
}

/*============================
//  FAQ Section
===========================*/

.tp-accordion {
   .accordion-item {
      border-bottom: 1px solid #E2E2E2;
      border-width: 0 0 1px 0;
      &:last-of-type {
         margin-bottom: 0;
      }
      
   }
   .accordion-body {
      padding-left: 0;
      padding-top: 20px;
      background-color: #fff;
      P {
         font-size: 18px;
         line-height: 30px;
      }
   }
   .accordion-button {
      font-size: 24px;
      font-weight: 700;
      line-height: 1.3;
      color: #161851;
      background: transparent;
      padding-left: 0;     
      &.collapsed {
         background-color: transparent;
         color: $black-color;
         @include transition();
      }

      &:focus {
         outline: 0;
         box-shadow: none;
      }
   }
}

@media (min-width: 768px) {
   .tp-accordion {
      .accordion-button { 
         font-size: 24px;
      }
   }
}

// overview 
.block-image {
   &.overview-image{
      max-width: 350px;
   }
}

/*============================
//  Intregration Section
===========================*/
.sec-intregration {
   background-size: cover;
   background-repeat: no-repeat;
   background-position: center;
   padding-bottom: 150px;
   .btn-link {
      color: $primary-color;
      font-size: 18px;
      font-weight: 700;
      .icon {
         font-size: 16px;
         margin-left: 5px;
      }
   }
}

@media (min-width: 992px){
   .sec-intregration {
      background-size: contain;
      padding-bottom: 200px;
   }
}

/*
 // team
*/
.team {
  
   &-thumb {
      position: relative;
      overflow: hidden;
      border-radius: 8px;
      .team-social {
         position: absolute;
         right: 10px;
         bottom: 20px;
         z-index: 1;
         opacity: 0;
         visibility: hidden;
         transition: ease-in 0.3s;
         transition-delay: ease-in-out 0.9s;
         a {
            display: block;
            width: 36px;
            height: 36px;
            font-size: 18px;
            color: #161851;
            background: #fff;
            text-align: center;
            border-radius: 50%;
            line-height: 37px;
            margin-bottom: 10px;
         }
      }
      &::after{
         position: absolute;
         content: "";
         left: 100%;
         top: 100%;
         width: 380px;
         height: 380px;
         background: #44933D;
         border-radius: 50%;
         transition: all ease 0.4s; 
      }
   }
   &-content {
      padding-top: 30px;
      .title {
         font-size: 20px;
         line-height: 25px;
         font-weight: 700;
         text-transform: capitalize;
         color: #161851;
      }
      .designation {
         font-size: 14px;
         font-weight: 400;
         color: #5E687C;
      }
   }
   &-single {
      position: relative;
      &:hover,
      &.active {
         .team-thumb {
            &::after{
               left: 45%;
               top: 45%;
            }
         }
         .team-social {
            right: 30px;
            visibility: visible;
            opacity: 1;
         }
      }
   }
}

// price

.price {
   &-single {
      padding: 45px;
      box-shadow: 0px 4px 90px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      .pricing-price {
         padding: 40px 0 18px;
         h3 {
            font-size: 48px;
            color: #000000;
            span {
               font-size: 18px;
            }
         }
      }
      .btn {
         border-radius: 25px;
      }
      .price-content {
         li {
            margin-bottom: 5px;
            i {
               color: $primary-color;
               margin-right: 10px;
            }
         }
      }
      &.active {
         background: $primary-color;
         color: #fff;
         h3 {
            color: #fff;
         }
         .price-header {
            p {
               color: #fff;
            }
         }
         .btn {
            background: #fff;
            color: $primary-color;
           
         }
        .price-content {
            li i {
               color: #fff;
            }
        }
      }
   }
   &-header {
      padding-bottom: 30px;
      p {
         font-size: 20px;
         color: #5E687C;
         margin: 0;
      }
   }
}

// contact 

.address {
   &-item {
      display: flex;
      h3 {
         font-size: 20px;
         font-weight: 700;
         color: #161851;
      }
      p {
         margin: 0;
         color: #5E687C;
      }
      .micon {
         font-size: 40px;
      }
      .address {
         margin-top: -9px;
         padding-left: 20px;
      }
   }
}

.minput {
   background: #F6F6F6;
   color: #AFB2B8;
   font-size: 16px;
   border-radius: 6px;
   padding: 10px 15px;
   border: none;
   &:focus {
      box-shadow: none;
      background: #F6F6F6;
   }
}

.content-box {
	background: $white-color;
	box-shadow: 0px 4px 70px 30px rgba(0, 0, 0, 0.05);
	border-radius: 10px;
	padding: 30px;
}
.box-title {
	font-size: 24px;
	line-height: 32px;
	color: $black-color;
	font-weight: 700;
}

.platform-section {
	padding: 100px 0;
   background-color: #FDF7E9;
	.section-heading {
      padding-top: 60px;
		width: calc(100% - 40px);
		margin: 0;
		.dot-shape {
			position: absolute;
			left: 0;
			z-index: -1;
			img {
				max-width: 168px;
				width: 100%;
			}
		}
		.rect-shape {
			position: absolute;
			right: 0;
			z-index: -1;
			img {
				max-width: 180px;
				width: 100%;
				object-fit: cover;
			}
		}
	}
   @media (min-width: 991px) {
      .section-heading {
         padding-top: 100px;
         padding-bottom: 260px;
         margin: 0 auto -205px;
      }
   }
}
.content-box {
	.icon {
		img {
			max-width: 68px;
			margin-bottom: 5px;
		}
	}
}


@media (min-width: 1024px) {
	.platform-section {
		padding: 100px 0;
      background-color: unset;
		.section-heading {
			.dot-shape {
				left: -43px;
			}
			.rect-shape {
				position: absolute;
				right: 0;
				img {
					max-width: 180px;
					width: 100%;
					object-fit: cover;
				}
			}
		}
      .platform-item:nth-child(even) {
         .content-box {
            margin-top: -40px;
         }
      }
	}
}

@media (min-width: 1300px) {
	.platform-section {
		padding: 100px 0;
		.section-heading {
			.dot-shape {
				left: -168px;
			}
			.rect-shape {
				right: 0;
			}
		}
	}
}

/*--------------------------------------------------
-------------------- rotate words ------------------
---------------------------------------------------*/

.spinny-words{
   display: inline-block;
   min-width: 350px;
   text-align: left;
}
.spinny-words span{
   position: absolute;
   font-weight: bold;
   top:-0px;
   opacity: 0;
   animation: rotateWord 18s linear infinite 0s;
}
.spinny-words span:nth-child(2) {
   animation-delay: 3s;
}
.spinny-words span:nth-child(3) {
   animation-delay: 6s;
}
.spinny-words span:nth-child(4) {
   animation-delay: 9s;
}
.spinny-words span:nth-child(5) {
   animation-delay: 12s;
}
.spinny-words span:nth-child(6) {
   animation-delay: 15s;
}
@keyframes rotateWord {
   0%   { opacity: 0; }
   2%   { opacity: 0; transform: translateY(-30px); }
   5%   { opacity: 1; transform: translateY(0px);}
   17%  { opacity: 1; transform: translateY(0px); }
   20%  { opacity: 0; transform: translateY(30px); }
   80%  { opacity: 0; }
   100% { opacity: 0; }
}